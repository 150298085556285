import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const ExpContainer = styled.div`
  box-shadow: 0px 9px 21px -10px rgba(0, 0, 0, 0.3);
  padding: 0.5em;
  margin: 2.5em 0 1em 0;
  background-color: white;
`
const PortfolioTitle = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
  text-align: center;
  margin: 2.5em 0 1em 0;
  scroll-behavior: smooth;
`
const Div = styled.div`
  margin: 2em 0;
`
const ExpTitle = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  margin: 25px;
`
const ExpPara = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  margin: 25px;
`
const ExpTags = styled.h3`
  display: inline-flex;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: lighter;
  margin: 0.4em;
  padding: 0.25em;
  border: solid;
  border-radius: 5px;
  border-color: #adcae6;
  width: fit-content;
`
const WebButton = styled.button`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px 10px 25px 25px;
  padding: 7px 15px 7px 15px;
  color: white;
  background-color: #adcae6;
  box-shadow: 0px 9px 21px -10px rgba(0, 0, 0, 0.3);
  border-color: #adcae6;
  width: fit-content;
`
const GitHubButton = styled.button`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px 10px 25px 25px;
  padding: 7px 15px 7px 15px;
  color: #adcae6;
  background-color: #f8fcfd;
  border-color: #f8fcfd;
  box-shadow: 0px 9px 21px -10px rgba(0, 0, 0, 0.3);
  width: fit-content;
`
const ListItem = styled.ul`
  font-family: Arial, Helvetica, sans-serif;
  list-style-type: none;
`
const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
`
const Title = styled.h1`
  font-size: 60px;
  font-family: Arial, Helvetica, sans-serif;
`
const SubTitle = styled.h2`
  font-family: Arial, Helvetica, sans-serif;
`
const Para = styled.p`
  font-family: Arial, Helvetica, sans-serif;
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 9px 21px -10px rgba(0, 0, 0, 0.3);
  padding: 2em 0 4em 0;
  margin: 2.5em 0 15em 0;
  background-color: white;
`
const ContactLink = styled.a`
  margin: 1em 1em;
  font-size: 2em;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #adcae6;
  @media (max-width: 700px) {
    font-size: 1.1em;
  }
`
const ContactTitle = styled.h1`
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
  text-align: center;
  margin: 4em 0 1em 0;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Div>
      <Title>Nathan Hussey</Title>
      <Para>
        I am a Full Stack Web Developer based in Toronto, Canada. I enjoy building
        applications that solve problems and makes life easier for their users.{" "}
      </Para>
    </Div>
    <Div>
      <SubTitle> Education</SubTitle>
      <ListItem>
        <li>B.Com Finance - University of Ottawa </li>
      </ListItem>
    </Div>
    <Div>
      <SubTitle> Technical Skills</SubTitle>
      <ListItem>
      <SubTitle style={{fontSize:'1rem', marginBottom:'0'}}> Front End: </SubTitle>
        <li>
        JavaScript, React, Redux, ES6, Gatsby, Nextjs, GraphQL (Client-Side), Styled-component, React-vis (d3), HTML5, CSS3, BootStrap 4, Ant Design, Webpack, Babel, SASS, SCSS, BEM
        </li>
        <SubTitle style={{fontSize:'1rem', marginBottom:'0'}}> Back End: </SubTitle>
        <li>
        Node.js, Express.js, Firebase, MongoDB, Mongoose, PostgreSQL, REST API Design, JWT, Bcrypt
        </li>
        <SubTitle style={{fontSize:'1rem', marginBottom:'0'}}> Testing, UX, & Others:  </SubTitle>
        <li>
        Enzyme.js, Jest, Git, NPM, Yarn, Figma, Photoshop, Illustrator, Adobe Creative Suite
        </li>
        <SubTitle style={{fontSize:'1rem', marginBottom:'0'}}> Principles & Methodologies: </SubTitle>
        <li>
        Test Driven Development, Responsive Design, Progressive Web Apps, Mobile Design First, OOP
        </li>
      </ListItem>
    </Div>
    <PortfolioTitle id="portfolio">Portfolio</PortfolioTitle>
    <ExpContainer>
      <ExpTitle>Sizify - Web App & Shopify App</ExpTitle>
      <ExpPara>
        This is an AI powered web application, that can determine a person's
        clothing size using 2 pictures.
      </ExpPara>
      <ul>
        <ExpTags>React</ExpTags>
        <ExpTags>AI</ExpTags>
        <ExpTags>Node.js</ExpTags>
        <ExpTags>Firebase</ExpTags>
        <ExpTags>Express.js</ExpTags>
      </ul>
      <a href="https://www.sizify.com/" target="_blank">
        <WebButton>Visit Website</WebButton>
      </a>
    </ExpContainer>
    <ExpContainer>
      <ExpTitle>Disciplined Agile Delivery - Web App</ExpTitle>
      <ExpPara>
        A web app developed to improve efficiency & accuracy when making
        decisions about Agile Software Development.
      </ExpPara>
      <ul>
        <ExpTags>ReactJS</ExpTags>
        <ExpTags>Redux</ExpTags>
        <ExpTags>Node</ExpTags>
        <ExpTags>Express</ExpTags>
        <ExpTags>Javascript</ExpTags>
        <ExpTags>MongoDB</ExpTags>
        <ExpTags>BEM</ExpTags>
        <ExpTags>SCSS</ExpTags>
        <ExpTags>Responsive Web Design</ExpTags>
      </ul>
    </ExpContainer>
    
    <ExpContainer>
      <ExpTitle>Teacher WFH Checklist - Web App</ExpTitle>
      <ExpPara>
        This web application was created for the Makers Festival - Work From
        Home Edition, <b>hosted by Product Hunt.</b> <br></br>
        <br></br>
        <b>Description:</b> A curated collection of resources & tips for
        teachers working from home.
      </ExpPara>
      <ul>
        <ExpTags>React</ExpTags>
        <ExpTags>JavaScript</ExpTags>
        <ExpTags>Node.js</ExpTags>
        <ExpTags>MongoDB</ExpTags>
        <ExpTags>Express.js</ExpTags>
        <ExpTags>Gatsby.js</ExpTags>
        <ExpTags>CSS Modules</ExpTags>
      </ul>
   
    </ExpContainer>
    <ExpContainer>
      <ExpTitle>Test Shuffle - Web App</ExpTitle>
      <ExpPara>
        This web application is designed to help teachers during test
        administration. The web app improves test result integrity by allowing
        teachers to create multiple version of their tests. Some technologies
        used React, Node.js, Express, MongoDB, and Ant Design.
      </ExpPara>
      <ul>
        <ExpTags>ReactJS</ExpTags>
        <ExpTags>Node</ExpTags>
        <ExpTags>Express</ExpTags>
        <ExpTags>Javascript</ExpTags>
        <ExpTags>HTML</ExpTags>
        <ExpTags>CSS</ExpTags>
        <ExpTags>Ant Design</ExpTags>
      </ul>
      <a href="https://github.com/nathanhussey/TestShuffler" target="_blank">
        <GitHubButton>GitHub</GitHubButton>
      </a>
    </ExpContainer>
    <ExpContainer>
      <ExpTitle>Stock Schema - Web App </ExpTitle>
      <ExpPara>
        Stock Schema is a stock data visualization web app that provides new
        insights to investors. This project was inspired by the countless hours
        that my fellow finance majors and I spent looking at financial
        information. I used the IEX Cloud API for financial data & ReactJS,
        Redux, React-vis(d3), Styled-component in the frontend.
      </ExpPara>
      <ul>
        <ExpTags>HTML</ExpTags>
        <ExpTags>CSS</ExpTags>
        <ExpTags>ReactJS</ExpTags>
        <ExpTags>Redux</ExpTags>
        <ExpTags>Javascript</ExpTags>
        <ExpTags>React-vis</ExpTags>
        <ExpTags>Styled-Component</ExpTags>
      </ul>
      <a href="https://github.com/nathanhussey/stockschema" target="_blank">
        <GitHubButton>GitHub</GitHubButton>
      </a>
    </ExpContainer>

    <ExpContainer>
      <ExpTitle>EdaptDB - Web App</ExpTitle>
      <ExpPara>
        This web application was created for the{" "}
        <b>Global COVID-19 Hackathon, hosted by DevPost.</b> The functioning
        prototype was design and developed in a 2 day period.<br></br>
        <br></br>
        <b>Description:</b> Schools sharing educational solutions during
        COVID19.
      </ExpPara>
      <ul>
        <ExpTags>React</ExpTags>
        <ExpTags>Node.js</ExpTags>
        <ExpTags>MongoDB</ExpTags>
        <ExpTags>Express.js</ExpTags>
        <ExpTags>JavaScript</ExpTags>
      </ul>

      <a href="https://github.com/nathanhussey/edaptDB" target="_blank">
        <GitHubButton>GitHub</GitHubButton>
      </a>
    </ExpContainer>
    <ExpContainer>
      <ExpTitle>Legadoe - 3D Printing & Scanning </ExpTitle>
      <ExpPara>
        I launched Legadoe, a 3D scanning company with the goal of improving
        cost and quality for customers in our niche. In order to acheive this, I
        developed and implemented a new business model that focused on
        leveraging new technologies. The company was featured at Niagara Fall
        Comic-Con 2017 with many happy customers. Some technologies used
        included HTML, CSS, Zbrush, MeshLab, Photoshop, Illustrator.
      </ExpPara>
      <ul>
        <ExpTags>HTML</ExpTags>
        <ExpTags>CSS</ExpTags>
        <ExpTags>ZBrush</ExpTags>
        <ExpTags>Meshmixer</ExpTags>
        <ExpTags>MeshLab</ExpTags>
        <ExpTags>Photoshop</ExpTags>
        <ExpTags>Illustrator</ExpTags>
      </ul>
    </ExpContainer>

    <ContactTitle id="contact">Contact Info</ContactTitle>
    <Container>
      <ContactLink href="mailto:nathanhussey.dev@gmail.com">Email</ContactLink>
      <ContactLink>|</ContactLink>
      <ContactLink
        href="https://www.linkedin.com/in/nathan-hussey/"
        target="_blank"
      >
        LinkedIn
      </ContactLink>
      <ContactLink>|</ContactLink>
      <ContactLink href="https://github.com/nathanhussey" target="_blank">
        Github
      </ContactLink>
    </Container>
  </Layout>
)

export default IndexPage
